/**
 * Created by osirvent on 09/11/2015.
 */
'user strict';
angular
    .module('annexaApp')
    .run(['$rootScope', '$window', '$state', '$translate', '$stateParams', 'Language', '$localStorage', 'formlyConfig', 'formlyValidationMessages', 'HeaderService', 'DTDefaultOptions', 'LoginService', 'AnnexaFormlyFactory', '$injector', '$http', 'tmhDynamicLocale', 'editableOptions', 'editableThemes', '$q', '$templateCache', '$urlRouter', 'treeConfig', 'WebSocketsFactory', 'CacheFactory', '$location', 'CommonService', '$timeout', 'DialogsFactory', '$filter', 'GlobalDataFactory', function($rootScope, $window, $state, $translate, $stateParams, Language, $localStorage, formlyConfig, formlyValidationMessages, HeaderService, DTDefaultOptions, LoginService, AnnexaFormlyFactory, $injector, $http, tmhDynamicLocale, editableOptions, editableThemes, $q, $templateCache, $urlRouter, treeConfig, WebSocketsFactory, CacheFactory, $location, CommonService, $timeout, DialogsFactory, $filter, GlobalDataFactory) {

        this.cargarUsuario = function(authTokenDecoded){
            var loggedUser = authTokenDecoded.userObject;
            loggedUser.isAuthenticated = true;
            loggedUser.roles = authTokenDecoded.roles;
            loggedUser.token = authTokenDecoded.token;
            loggedUser.tenant = authTokenDecoded.tenant;
           	loggedUser.apiUrl = $rootScope.app.baseUrl + 'api/users/' + loggedUser.id;
            loggedUser.managedOrgans = authTokenDecoded.managedOrgans;

            return loggedUser;
        }
        $rootScope.app = new App(
                'Annexa',
                '1.0.0',
                window.location.protocol + '//' + window.location.host + window.location.pathname,
                '#0cc2aa',
                '#a88add',
                '#fcc100',
                '#6887ff',
                '#6cc788',
                '#f77a99',
                '#f44455',
                '#ffffff',
                '#f1f2f3',
                '#2e3e4e',
                '#2a2b3c',
                new Setting('primary', 'accent', 'warn', false, false, false, 1, ''),
                {},
                undefined,
                {}
            );
        $rootScope.userLoaded = false;
        $rootScope.$state = $state;
        $rootScope.$stateParams = $stateParams;
        $rootScope.headButtons = [];
        $rootScope.subHeadButtons = [];
        $rootScope.subHeadTabs = [];
        $rootScope.showLoadingdivSignin = false;
        $rootScope.showLoadingDivState = false;
        $rootScope.LoadedUser = undefined;
        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression = 'fc.$touched || form.$submitted';
        formlyConfig.extras.removeChromeAutoComplete = true;
        editableOptions.theme = 'bs3';
        editableOptions.icon_set = 'font-awesome';
        editableThemes.bs3.inputClass = 'form-control-sm';
        editableThemes.bs3.buttonsClass = 'btn-sm';

        $templateCache.put('selectize/choices.tpl.html', [
            '<div ng-show="$select.open"',
            '  class="ui-select-choices group-tree selectize-dropdown single">',
            '  <div ng-show="breadcrumbs.length > 1" class="ui-select-breadcrumbs">',
            '    <span class="ui-breadcrumb" ng-repeat="crumb in breadcrumbs"',
            '       ng-click="navigateBackTo(crumb, $select)">',
            '       {{crumb.title}}',
            '    </span>',
            '  </div>',
            '  <div class="ui-select-choices-content selectize-dropdown-content">',
            '    <div class="ui-select-choices-group optgroup">',
            '      <div ng-show="$select.isGrouped"',
            '        class="ui-select-choices-group-label optgroup-header">',
            '        {{$group}}',
            '      </div>',
            '      <div class="ui-select-choices-row">',
            '        <div class="option ui-select-choices-row-inner"',
            '           data-selectable="">',
            '        </div>',
            '      </div>',
            '    </div>',
            '  </div>',
            '</div>'
        ].join(''));

        $templateCache.put("template/tabs/tab.html",
            "<li ng-class=\"[{active: active, disabled: disabled}, classes]\" class=\"uib-tab nav-item\">\n" +
            "  <a href ng-click=\"select($event)\" class=\"nav-link\" uib-tab-heading-transclude>{{heading}}</a>\n" +
            "</li>\n" +
            "");

        DTDefaultOptions.setLoadingTemplate('<div id="loadingdiv" ng-show="showLoadingdiv" aria-hidden="true" ><img src="./assets/images/loading.gif" class="ajax-loader" alt="spinner loader" /></div>');


        if ($location.path() == '/access/authenticateWithPlugin' || $location.path() == '/access/authenticateWithPlugin/') {
    		$rootScope.LoggedUser={'tokenAuthenticateWithPlugin':$location.search().tokenAuthenticateWithPlugin};        		
    		$state.go('access.authenticateWithPlugin');
    	}else if($location.path() == '/access/callbackOAuth' || $location.path() == '/access/callbackOAuth/'){
    		$rootScope.LoggedUser={'codeOAuth':$location.search().codeOAuthCallback};        		
    		$state.go('access.callbackOAuth');
    	}else if($localStorage.LoggedUserID) {
            if($localStorage.LoggedUserToken && $localStorage.LoggedUserTenant) {
            	var callLogin = true;
            	if(CacheFactory.get("globalDataCache") &&  CacheFactory.get("globalDataCache").get('LoggedUserData')){
               		var cache = CacheFactory.get('globalDataCache').get('LoggedUserData');
               		if(cache && cache.userObject && cache.userObject.id){
               			var tmp = JSOG.decode(angular.fromJson(cache));
               			if(tmp && tmp.userObject && tmp.tenant == $localStorage.LoggedUserTenant && tmp.token == $localStorage.LoggedUserToken && tmp.userObject.id == $localStorage.LoggedUserID){
               				if(!$rootScope.userLoaded) {
	                        	var LoadedUser = angular.fromJson(cache);
	                            var loggedUser = this.cargarUsuario(tmp);
	                            var postLogin = function(){
		                        	LoginService.PostLoginActions(
		                                    JSOG.decode(LoadedUser.userObject),
		                                    LoadedUser.roles, LoadedUser.token,
		                                    undefined,
		                                    LoadedUser.tenant,
		                                    tmp.modules,
		                                    tmp.managedOrgans,
		                                    tmp.addAutomaticActionPermissions,
		                                    tmp.annexaVersion,
		                                    tmp.activeMultipleRegisterOffice,
		                                    tmp.registerEntryOffices,
		                                    undefined,
		                                    true,
		                                    tmp.actionPermissions
		                                );
	                            }
	                            if(loggedUser.language && loggedUser.language.acronym){
	                            	$translate.preferredLanguage(loggedUser.language.acronym);
	                                tmhDynamicLocale.set(loggedUser.language.acronym);
	                                $rootScope.app.language = loggedUser.language.acronym;
	                                Language.setCurrent(loggedUser.language.acronym);
	                                $rootScope.app.langColumn = Language.getActiveColumn();
	                                if($translate.use() != loggedUser.language.acronym){
	                                    $translate.use(loggedUser.language.acronym).then(function(){
	                                        $translate.refresh();
	                                        postLogin();
	                                    });
	                                }else{
	                                	postLogin();
	                                }
	                            }else{
	                            	postLogin();
	                            }
	                            callLogin = false;
	                        }
               			}
                	}
                }
            	if(callLogin){
	                $http(
	                    {
	                        method: 'GET',
	                        url: './api/users/getUserLogged',
	                        params: { id: $localStorage.LoggedUserID, action : 'authenticateAnnexaAngular' },
	                        headers: { 'x-auth-token' : $localStorage.LoggedUserToken, 'x-tenant-ID': $localStorage.LoggedUserTenant }
	                    }
	                ).then(function(data) {
	                    if(data.status == 200) {
	                        if(!$rootScope.userLoaded) {
	                        	if(CacheFactory.get("globalDataCache")){
	                        		try {
	                            		CacheFactory.get("globalDataCache").put('LoggedUserData',data.data);
	                        		} catch (error) {
	                        			console.error(error);
	                        		}
	                            }
	                            var tmp = JSOG.decode(data.data);
	                            var LoadedUser = data.data;
	
	                            var loggedUser = this.cargarUsuario(tmp);
	
	                            var postLogin = function(){
		                        	LoginService.PostLoginActions(
		                                    JSOG.decode(LoadedUser.userObject),
		                                    LoadedUser.roles, LoadedUser.token,
		                                    undefined,
		                                    LoadedUser.tenant,
		                                    tmp.modules,
		                                    tmp.managedOrgans,
		                                    tmp.addAutomaticActionPermissions,
		                                    tmp.annexaVersion,
		                                    tmp.activeMultipleRegisterOffice,
		                                    tmp.registerEntryOffices,
		                                    undefined,
		                                    undefined,
		                                    tmp.actionPermissions
		                                );
	                            }
	                            if(loggedUser.language && loggedUser.language.acronym){
	                            	$translate.preferredLanguage(loggedUser.language.acronym);
	                                tmhDynamicLocale.set(loggedUser.language.acronym);
	                                $rootScope.app.language = loggedUser.language.acronym;
	                                Language.setCurrent(loggedUser.language.acronym);
	                                $rootScope.app.langColumn = Language.getActiveColumn();
	                                if($translate.use() != loggedUser.language.acronym){
	                                    $translate.use(loggedUser.language.acronym).then(function(){
	                                        $translate.refresh();
	                                        postLogin();
	                                    });
	                                }else{
	                                	postLogin();
	                                }
	                            }else{
	                            	postLogin();
	                            }
	                        }
	                    }
	                }).catch(function (data) {
	                    $localStorage.LoggedUserID = undefined;
	                    $localStorage.LoggedUserToken = undefined;
	                    $localStorage.LoggedUserTenant = undefined;
	                    $state.go('access.signin');
	                });
            	}
            } else {
                $localStorage.LoggedUserID = undefined;
                $localStorage.LoggedUserToken = undefined;
                $localStorage.LoggedUserTenant = undefined;
                $state.go('access.signin');
            }
        } else {
            $state.go('access.signin');
        }

        $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams) {
            if (toState.external) {
                event.preventDefault();
                $window.open(toState.url, '_blank');
            } else {
                $rootScope.loadingState(true);
                if ($rootScope.allModules != undefined || toState.name == 'access.signin') {
                    var redirect = toState.redirectTo;
                    if (redirect) {
                        if (angular.isString(redirect)) {
                            if ($state.current && $state.current.name == redirect) {
                                event.preventDefault();
                                $state.reload();
                            } else {
                                event.preventDefault();
                                $state.go(redirect, toStateParams);
                            }
                        } else {
                            var newState = $injector.invoke(redirect, null, {
                                toState: toState,
                                toParams: toStateParams
                            });
                            if (newState) {
                                if (angular.isString(newState)) {
                                    event.preventDefault();
                                    $state.go(newState);
                                } else if (newState.state) {
                                    event.preventDefault();
                                    $state.go(newState.state, newState.params);
                                }
                            }
                        }
                    }

                    $rootScope.toState = toState;
                    $rootScope.toStateParams = toStateParams;

                    if (toState.data !== undefined && toState.data.authenticate !== undefined) {
                        if (toState.data.authenticate && !$rootScope.isAuthenticated()) {
                            event.preventDefault();
                            $rootScope.desiredtoState = undefined;
                            $rootScope.desiredtoStateParams = undefined;
                            $state.transitionTo('access.signin');
                        } else {
                            $rootScope.desiredtoState = toState.name;
                            $rootScope.desiredtoStateParams = toStateParams;
                        }
                    }
//                    Language.getCurrent().then(function (language) {
//                        $translate.use(language).then(function () {
//                            $translate.refresh();
//                        });
//                        tmhDynamicLocale.set(language);
//                    });
                }
                else { 
                    event.preventDefault();
                    $rootScope.desiredtoState = toState.name;
                    $rootScope.desiredtoStateParams = toStateParams;
                }
            }
        });

        $rootScope.isAuthenticated = function() {
            if($localStorage.LoggedUserID === undefined) {
                return false;
            }
            return true;
        };

        $rootScope.hasRole = function(role) {

            if (!$rootScope.isAuthenticated()) {
                return false;
            }
            if ($rootScope.LoggedUser.roles[role] === undefined) {
                return false;
            }
            return $rootScope.LoggedUser.roles[role];
        };

        $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
            $rootScope.loadingState(false);
            $rootScope.previousState = fromState;
            $rootScope.previousStateParams = fromParams;

            var titleKey = 'global.title';

            if(toState.data.pageTitle) {
                titleKey = toState.data.pageTitle;
            }

            $translate(titleKey).then(function (title) {
                $window.document.title = title;
            });

            formlyValidationMessages.addStringMessage('required', $translate.instant('global.validation.required'));
            formlyValidationMessages.addStringMessage('number', $translate.instant('global.validation.invalidNumber'));
            formlyValidationMessages.addStringMessage('dateRange', $translate.instant('global.validation.dateRange'));
            formlyValidationMessages.addStringMessage('email', $translate.instant('global.validation.email'));
            formlyValidationMessages.addStringMessage('documentTypeInvalid', $translate.instant('global.validation.documentTypeInvalid'));
            formlyValidationMessages.addStringMessage('spaceError', $translate.instant('global.validation.spaceError'));
            formlyValidationMessages.addStringMessage('maxlength', $translate.instant('global.validation.maxlength'));
            formlyValidationMessages.addStringMessage('requiredOne', $translate.instant('global.validation.requiredOne'));
            formlyValidationMessages.addStringMessage('requiredCouncillor', $translate.instant('global.validation.requiredCouncillor'));
			formlyValidationMessages.addStringMessage('requiredDocument', $translate.instant('global.validation.required'));
            formlyValidationMessages.addStringMessage('iban', $translate.instant('global.validation.iban'));
            HeaderService.changeState(toState);

        });

        $rootScope.$on('$stateNotFound',
            function(event, unfoundState, fromState, fromParams){
                $rootScope.loadingState(false);
                console.log(unfoundState.to);
                console.log(unfoundState.toParams);
                console.log(unfoundState.options);
            });

        $rootScope.$on('$stateChangeError',
            function(event, toState, toParams, fromState, fromParams, error){
                $rootScope.loadingState(false);
                console.log(error);
            });

        $rootScope.loading = function(state) {
            $rootScope.showLoadingdivSignin = state;
        };

        $rootScope.loadingState = function(state) {
            $rootScope.showLoadingDivState = state;
        }
        $rootScope.permEnableElement = function ($element) {
            $element.removeAttr('disabled');
            $element.removeClass('perm-disabled');
        };

        $rootScope.permDisableElement = function ($element) {
            $element.attr('disabled', 'disabled');
            $element.addClass('perm-disabled');
        };


        var isolateScope = {
            onChange: '&',
            onAfterValidate: '&',
            parser: '&'
        };

        var FILE_READER_EVENTS = ['onabort', 'onerror', 'onloadstart', 'onloadend', 'onprogress', 'onload'];

        FILE_READER_EVENTS.forEach(function(e) {
            isolateScope[e] = '&';
        });

        formlyConfig.setType({
            name: 'annexaFileUploadBase64',
            extends: 'input',
            templateUrl: './views/templates/formly/annexafileuploadbase64.html',
            wrapper: ['bootstrapHasError'],
            link: function(scope, elem, attrs) {
                var rawFiles = [];
                var fileObjects = [];

                var ngModel = scope.form;

                //region Validations

                function _maxnum(val) {
                    if (attrs.maxnum && attrs.multiple && val) {
                        var valid = val.length <= parseInt(attrs.maxnum);
                        ngModel.$setValidity('maxnum', valid);
                    }
                    return val;
                }

                function _minnum(val) {
                    if (attrs.minnum && attrs.multiple && val) {
                        var valid = val.length >= parseInt(attrs.minnum);
                        ngModel.$setValidity('minnum', valid);
                    }
                    return val;
                }

                function _maxsize(val) {
                    var valid = true;

                    if (attrs.maxsize && val) {
                        var max = parseFloat(attrs.maxsize) * 1000;

                        if (attrs.multiple) {
                            for (var i = 0; i < val.length; i++) {
                                var file = val[i];
                                if (file.filesize > max) {
                                    valid = false;
                                    break;
                                }
                            }
                        } else {
                            valid = val.filesize <= max;
                        }
                        ngModel.$setValidity('maxsize', valid);
                    }

                    return val;
                }

                function _minsize(val) {
                    var valid = true;
                    var min = parseFloat(attrs.minsize) * 1000;

                    if (attrs.minsize && val) {
                        if (attrs.multiple) {
                            for (var i = 0; i < val.length; i++) {
                                var file = val[i];
                                if (file.filesize < min) {
                                    valid = false;
                                    break;
                                }
                            }
                        } else {
                            valid = val.filesize >= min;
                        }
                        ngModel.$setValidity('minsize', valid);
                    }

                    return val;
                }

                function _accept(val) {
                    var valid = true;
                    var regExp, exp, fileExt;
                    if (attrs.accept) {
                        exp = attrs.accept.trim().replace(/[,\s]+/gi, "|").replace(/\./g, "\\.").replace(/\/\*/g, "/.*");
                        regExp = new RegExp(exp);
                    }

                    if (attrs.accept && val) {
                        if (attrs.multiple) {
                            for (var i = 0; i < val.length; i++) {
                                var file = val[i];
                                fileExt = "." + file.filename.split('.').pop();
                                valid = regExp.test(file.filetype) || regExp.test(fileExt);

                                if (!valid) {
                                    break;
                                }
                            }
                        } else {
                            fileExt = "." + val.filename.split('.').pop();
                            valid = regExp.test(val.filetype) || regExp.test(fileExt);
                        }
                        ngModel.$setValidity('accept', valid);
                    }

                    return val;
                }

                //endregion


                function _setViewValue() {
                    var newVal = attrs.multiple ? fileObjects : fileObjects[0];
                    scope.fc.$setViewValue(newVal);
                    _maxsize(newVal);
                    _minsize(newVal);
                    _maxnum(newVal);
                    _minnum(newVal);
                    _accept(newVal);

                    $rootScope.$broadcast('annexaFileUploadBase64Change', { files: newVal });
                }

                function _attachHandlerForEvent(eventName, handler, fReader, file, fileObject) {
                    fReader[eventName] = function(e) {
                        handler()(e, fReader, file, rawFiles, fileObjects, fileObject);
                    };
                }

                function _readerOnLoad(fReader, file, fileObject) {

                    return function(e) {

                        var buffer = e.target.result;
                        var promise;

                        // do not convert the image to base64 if it exceeds the maximum
                        // size to prevent the browser from freezing
                        var exceedsMaxSize = attrs.maxsize && file.size > attrs.maxsize * 1024;
                        if (attrs.doNotParseIfOversize !== undefined && exceedsMaxSize) {
                            fileObject.base64 = null;
                        } else {
                            fileObject.base64 = $window._arrayBufferToBase64(buffer);
                        }

                        if (attrs.parser) {
                            promise = $q.when(scope.parser()(file, fileObject));
                        } else {
                            promise = $q.when(fileObject);
                        }

                        promise.then(function(fileObj) {
                            fileObjects.push(fileObj);
                            // fulfill the promise here.
                            file.deferredObj.resolve();
                        });

                        if (attrs.onload) {
                            if (scope.onload && typeof scope.onload() === "function") {
                                scope.onload()(e, fReader, file, rawFiles, fileObjects, fileObject);
                            } else {
                                scope.onload(e, rawFiles);
                            }
                        }

                    };

                }

                function _attachEventHandlers(fReader, file, fileObject) {

                    for (var i = FILE_READER_EVENTS.length - 1; i >= 0; i--) {
                        var e = FILE_READER_EVENTS[i];
                        if (attrs[e] && e !== 'onload') { // don't attach handler to onload yet
                            _attachHandlerForEvent(e, scope[e], fReader, file, fileObject);
                        }
                    }

                    fReader.onload = _readerOnLoad(fReader, file, fileObject);
                }

                function _readFiles() {
                    var promises = [];
                    var i;
                    for (i = rawFiles.length - 1; i >= 0; i--) {
                        // append file a new promise, that waits until resolved
                        rawFiles[i].deferredObj = $q.defer();
                        promises.push(rawFiles[i].deferredObj.promise);
                        // TODO: Make sure all promises are resolved even during file reader error, otherwise view value wont be updated
                    }

                    // set view value once all files are read
                    $q.all(promises).then(_setViewValue);

                    for (i = rawFiles.length - 1; i >= 0; i--) {
                        var reader = new $window.FileReader();
                        var file = rawFiles[i];
                        var fileObject = {};

                        fileObject.filetype = file.type;
                        fileObject.filename = file.name;
                        fileObject.filesize = file.size;

                        _attachEventHandlers(reader, file, fileObject);
                        reader.readAsArrayBuffer(file);
                    }
                }

                function _onChange(e) {
                    if (attrs.onChange) {
                        if (scope.onChange && typeof scope.onChange() === "function") {
                            scope.onChange()(e, rawFiles);
                        } else {
                            scope.onChange(e, rawFiles);
                        }
                    }
                }

                function _onAfterValidate(e) {
                    if (attrs.onAfterValidate) {
                        // wait for all promises, in rawFiles,
                        //   then call onAfterValidate
                        var promises = [];
                        for (var i = rawFiles.length - 1; i >= 0; i--) {
                            promises.push(rawFiles[i].deferredObj.promise);
                        }
                        $q.all(promises).then(function() {
                            if (scope.onAfterValidate && typeof scope.onAfterValidate() === "function") {
                                scope.onAfterValidate()(e, fileObjects, rawFiles);
                            } else {
                                scope.onAfterValidate(e, fileObjects, rawFiles);
                            }
                        });
                    }
                }

                ngModel.$isEmpty = function(val) {
                    return !val || (angular.isArray(val) ? val.length === 0 : !val.base64);
                };

                scope._clearInput = function() {
                    elem[0].value = '';
                };

                scope.$watch(function() {
                    return ngModel.$viewValue;
                }, function(val) {
                    if (ngModel.$isEmpty(val) && ngModel.$dirty) {
                        scope._clearInput();
                        // Remove validation errors
                        ngModel.$setValidity('maxnum', true);
                        ngModel.$setValidity('minnum', true);
                        ngModel.$setValidity('maxsize', true);
                        ngModel.$setValidity('minsize', true);
                        ngModel.$setValidity('accept', true);
                    }
                });

                elem.on('change', function(e) {

                    attrs.multiple = e.target.multiple;

                    fileObjects = [];
                    fileObjects = angular.copy(fileObjects);

                    if (e.target.files.length === 0) {
                        rawFiles = [];
                        _setViewValue();
                    } else {
                        rawFiles = e.target.files; // use event target so we can mock the files from test
                        _readFiles();
                        _onChange(e);
                        _onAfterValidate(e);
                    }

                    scope._clearInput();

                    $rootScope.$broadcast('annexaFileUploadBase64Changed')
                });
            }
        });
        $rootScope.$on('sentToSignProcessesAdded', function(event, args) {
        	if(args && args.actionsSigned && args.actionsSigned.length > 0){
        		var getStatusSignProcessRootScope = function(process){
        			if(process && process.length > 0){
		    			$http({
		                    method: 'GET',
		                    url: './api/sign/statusSignProcess/'+CommonService.getParameterList($linq(process).select("x=>x.id").toArray())
		        		}).success(function(retStatus, status) {
		                    if(retStatus && retStatus.result) {
		                    	if(retStatus && retStatus.error){
		                    		var error = $filter('translate')('global.literals.sendToSignAllDocumentsOKERROR');
		                    		if(retStatus.errors && retStatus.errors.length > 0){
		                    			error += "<ul>"
		                    			_.forEach(retStatus.errors, function(err){
		                    				var proc = $linq(process).firstOrDefault(undefined, "x => x.id == "+err.idAction);
		                    				if(proc && proc.docName){
		                    					error += "<li>"+proc.docName+"  "+((err.error)?$filter('translate')(err.error):"error")+((err.error && err.error == 'global.errors.unknowHostError' && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.sign_util_configuration && $rootScope.app.configuration.sign_util_configuration.timeStampUrl)?$rootScope.app.configuration.sign_util_configuration.timeStampUrl:'')+((err.errorException)?"<br/>"+err.errorException:"")+"</li>";
		                    				}
		                    			});
		                    			error += "</ul>"
		                    		}
		                    		DialogsFactory.notify(error);
		                    	}else if(retStatus && retStatus.rejected) {
		                    		var error = $filter('translate')('global.literals.sendToSignAllDocumentsOKREJECT');
		                    		if(retStatus.errors && retStatus.errors.length > 0){
		                    			error += "<ul>"
		                    			_.forEach(retStatus.errors, function(err){
		                    				var proc = $linq(process).firstOrDefault(undefined, "x => x.id == "+err.idAction);
		                    				if(proc && proc.docName){
		                    					error += "<li>"+proc.docName+((err.error)?'. '+$filter('translate')('global.literals.rejectReason')+': '+err.error:'')+"</li>";
		                    				}
		                    			});
		                    			error += "</ul>"
		                    		}
		                    		DialogsFactory.notify(error);
		                    	}else{
		                    		DialogsFactory.notify($filter('translate')('global.literals.sendToSignAllDocumentsOK'));
		                    	}
		                    }else{
		                    	$timeout(function() {getStatusSignProcessRootScope(process)}, 6000);
		                    }
		                }).error(function(msg, code) {
		                	console.log(msg);
		                });
	        		}
	    		}
        		$timeout(function() {getStatusSignProcessRootScope(args.actionsSigned)}, 6000);	    	 		
        	}
        });
        
        $rootScope.$on('generateCertificatesAdded', function(event, args) {
            if(args && args.sessionId){
                var getStatusSessionGenerateCertificatesRootScope = function(sessionId){
                    if(sessionId){
                        $http({
                            method: 'GET',
                            url: './api/sec/session/satusSessionGenerateCertificates/'+sessionId
                        }).success(function(retStatus, status) {
                            if(retStatus && retStatus.result) {
                                if(retStatus && retStatus.error){
                                    var error = $filter('translate')('global.errors.generateCertificatesOKERROR');
                                    error += "<ul>"
                                    _.forEach(retStatus.errors, function(err){
                                    	error += "<li>"+err.cert+":  <br/>"+((err.errorException)?err.errorException:"global.errors.errorGenerateCertificates")+"</li>";
                                    });
                                    error += "</ul>"
                                    DialogsFactory.notify(error);
                                }else{
                                    DialogsFactory.notify($filter('translate')('global.errors.generateCertificatesOK', { sessionNumber: retStatus.sessionNumber}));
                                }
                                if($state && $state.current && $state.current.name === 'annexa.sec.sessions.view' && $state.params && $state.params.session && $state.params.session === sessionId+''){
                                	$state.reload();
                                }
                            }else{
                                $timeout(function() {getStatusSessionGenerateCertificatesRootScope(sessionId)}, 6000);
                            }
                        }).error(function(msg, code) {
                            console.log(msg);
                        });
                    }
                }
                $timeout(function() {getStatusSessionGenerateCertificatesRootScope(args.sessionId)}, 6000);                     
            }
        });    
        WebSocketsFactory.initialize();
    }])
    .config(['$stateProvider', '$urlRouterProvider', function( $stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise(function($injector, $location) {
            var $state = $injector.get("$state");
            $state.go('annexa.dashboard');
        });

        $stateProvider
            .state('annexa', {
                abstract: true,
                url: '/annexa',
                views: {
                    '': {
                        templateUrl: './views/layout.html'
                    }
                },
                data: {
                    breadcrumbProxy: 'annexa.dashboard'
                },
                resolve: {
                    globalData: ['GlobalDataFactory', '$q', '$translate', 'tmhDynamicLocale', '$rootScope', function(GlobalDataFactory, $q, $translate, tmhDynamicLocale, $rootScope) {
                        var deferrend = $q.defer();
                        GlobalDataFactory.loadGlobalData().then(function(data){
                            deferrend.resolve("OK");
                        }).catch(function(error) {
                            deferrend.resolve("Error");
                        });
                        return deferrend.promise;

                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', '$q', function ($translate, $translatePartialLoader, $rootScope, $q) {
                    	var deferrend = $q.defer();
                    	if($rootScope && $rootScope.LoggedUser && $rootScope.LoggedUser.language && $rootScope.LoggedUser.language.acronym && $translate.use() != $rootScope.LoggedUser.language.acronym) {
                    		$translate.use($rootScope.LoggedUser.language.acronym).then(function() {
                                $translatePartialLoader.addPart('global');
                                $translate.refresh().then(function(data){
                                    deferrend.resolve("OK");
                                }).catch(function(error) {
                                    deferrend.resolve("Error");
                                });
                    		}).catch(function() {
                                $translatePartialLoader.addPart('global');
                                $translate.refresh().then(function(data){
                                    deferrend.resolve("OK");
                                }).catch(function(error) {
                                    deferrend.resolve("Error");
                                });
                    		});
                    	} else {
                            $translatePartialLoader.addPart('global');
                            $translate.refresh().then(function(data){
                                deferrend.resolve("OK");
                            }).catch(function(error) {
                                deferrend.resolve("Error");
                            });
                    	}
                        return deferrend.promise;
                    }],

                    user: ['$localStorage', '$http', 'LoginService', '$state', '$q', '$rootScope', '$translate', 'tmhDynamicLocale', 'Language', 'CacheFactory', function ($localStorage, $http, LoginService, $state, $q, $rootScope, $translate, tmhDynamicLocale, Language, CacheFactory) {
                        if(!$rootScope.userLoaded) {
                            if ($localStorage.LoggedUserID) {
                                if ($localStorage.LoggedUserToken && $localStorage.LoggedUserTenant) {
                                	var callLogin = true;
                                	if(CacheFactory.get("globalDataCache") &&  CacheFactory.get("globalDataCache").get('LoggedUserData')){
                                   		var cache = CacheFactory.get('globalDataCache').get('LoggedUserData');
                                   		if(cache && cache.userObject && cache.userObject.id){
                                   			var tmp = JSOG.decode(angular.fromJson(cache));
                                   			if(tmp && tmp.userObject && tmp.tenant == $localStorage.LoggedUserTenant && tmp.token == $localStorage.LoggedUserToken && tmp.userObject.id == $localStorage.LoggedUserID){
	                                            var LoadedUser = angular.fromJson(cache);
	                                            var loggedUser = this.cargarUsuario(tmp);
	                                            var postLogin = function(){
	                                            	LoginService.PostLoginActions(
	                                                        JSOG.decode(LoadedUser.userObject),
	                                                        LoadedUser.roles,
	                                                        LoadedUser.token,
	                                                        undefined,
	                                                        LoadedUser.tenant,
	                                                        tmp.modules,
	                                                        tmp.managedOrgans,
	                                                        tmp.addAutomaticActionPermissions,
	                                                        tmp.annexaVersion,
	                                                        tmp.activeMultipleRegisterOffice,
	                                                        tmp.registerEntryOffices,
	                                                        undefined,
	                                                        undefined,
	                                                        tmp.actionPermissions
	                                                    );
	                                            }
	                                            
	                                            if(loggedUser.language && loggedUser.language.acronym){
	                                                $translate.preferredLanguage(loggedUser.language.acronym);
	                                                tmhDynamicLocale.set(loggedUser.language.acronym);
	                                                $rootScope.app.language = loggedUser.language.acronym;
	                                                Language.setCurrent(loggedUser.language.acronym);
	                                                $rootScope.app.langColumn = Language.getActiveColumn();
	                                                if($translate.use() != loggedUser.language.acronym){
		                                                $translate.use(loggedUser.language.acronym).then(function(){
		                                                    $translate.refresh();
		                                                    postLogin();
		                                                });
	                                                }else{
	                                                	postLogin();
	                                                }
	                                            }else{
	                                            	postLogin();
	                                            }
                	                            callLogin = false;
                                   			}
                                   		}
                                	}
                                	if(callLogin){
	                                    return $http(
	                                        {
	                                            method: 'GET',
	                                            url: './api/users/getUserLogged',
	                                            params: {id: $localStorage.LoggedUserID, action : 'authenticateAnnexaAngular'},
	                                            headers: {
	                                                'x-auth-token': $localStorage.LoggedUserToken,
	                                                'x-tenant-ID': $localStorage.LoggedUserTenant
	                                            }
	                                        }
	                                    ).then(function (data) {
	                                        if (data.status == 200) {
	                                        	var tmp = JSOG.decode(data.data);
	                                        	if(CacheFactory.get("globalDataCache")){
	                                        		try {
	                                            		CacheFactory.get("globalDataCache").put('LoggedUserData',data.data);
	                                        		} catch (error) {
	                                        			console.error(error);
	                                        		}
	                                            }
	                                            var LoadedUser = data.data;
	
	                                            var loggedUser = this.cargarUsuario(tmp);
	                                            var postLogin = function(){
	                                            	LoginService.PostLoginActions(
	                                                        JSOG.decode(LoadedUser.userObject),
	                                                        LoadedUser.roles,
	                                                        LoadedUser.token,
	                                                        undefined,
	                                                        LoadedUser.tenant,
	                                                        tmp.modules,
	                                                        tmp.managedOrgans,
	                                                        tmp.addAutomaticActionPermissions,
	                                                        tmp.annexaVersion,
	                                                        tmp.activeMultipleRegisterOffice,
	                                                        tmp.registerEntryOffices,
	                                                        undefined,
	                                                        undefined,
	                                                        tmp.actionPermissions
	                                                    );
	                                            }
	                                            
	                                            if(loggedUser.language && loggedUser.language.acronym){
	                                                $translate.preferredLanguage(loggedUser.language.acronym);
	                                                tmhDynamicLocale.set(loggedUser.language.acronym);
	                                                $rootScope.app.language = loggedUser.language.acronym;
	                                                Language.setCurrent(loggedUser.language.acronym);
	                                                $rootScope.app.langColumn = Language.getActiveColumn();
	                                                if($translate.use() != loggedUser.language.acronym){
		                                                $translate.use(loggedUser.language.acronym).then(function(){
		                                                    $translate.refresh();
		                                                    postLogin();
		                                                });
	                                                }else{
	                                                	postLogin();
	                                                }
	                                            }else{
	                                            	postLogin();
	                                            }
	                                        }
	                                    }).catch(function (data) {
	                                        $localStorage.LoggedUserID = undefined;
	                                        $localStorage.LoggedUserToken = undefined;
	                                        $localStorage.LoggedUserTenant = undefined;
	                                        $state.go('access.signin');
	                                    });
                                	}
                                } else {
                                    $localStorage.LoggedUserID = undefined;
                                    $localStorage.LoggedUserToken = undefined;
                                    $localStorage.LoggedUserTenant = undefined;
                                    $state.go('access.signin');
                                }
                            } else {
                                $state.go('access.signin');
                            }
                        } else {
                            $q.resolve('User loaded');
                        }
                    }],
					loadInjectCSS:['injectCSS', function(injectCSS) {
                       return injectCSS.setCSS();
                    }]
                }
            })
            .state('access.authenticateWithPlugin', {
                url: '/authenticateWithPlugin',
                data: {
                    title: 'global.toptitle',
                    displayName: 'global.breadcrumbs.home'
                },
                resolve: {
                    loginWithPlugin:['LoginFactory','LoginService', '$timeout', '$q', '$state', '$rootScope', '$translate', 'tmhDynamicLocale', 'Language','CacheFactory',
                    	function(LoginFactory, LoginService, $timeout, $q, $state, $rootScope, $translate, tmhDynamicLocale, Language, CacheFactory) {
                    	
                		var model= {};
                		var callLogin = true;
                    	if(CacheFactory.get("globalDataCache") &&  CacheFactory.get("globalDataCache").get('LoggedUserData')){
                       		var cache = CacheFactory.get('globalDataCache').get('LoggedUserData');
                       		if(cache && cache.userObject && cache.userObject.id){
                       			var tmp = JSOG.decode(angular.fromJson(cache));
                       			if(tmp && tmp.userObject && tmp.tenant == $localStorage.LoggedUserTenant && tmp.token == $localStorage.LoggedUserToken && tmp.userObject.id == $localStorage.LoggedUserID){
                       				if(!$rootScope.userLoaded) {
	                      				var loggedUser = this.cargarUsuario(tmp);
	                       				var postLogin = function(){
	 	                                	LoginService.PostLoginActions(
	 	                                			tmp.userObject,
	 	                                			tmp.roles,
	 	                                			tmp.token,
	 	                                            undefined,
	 	                                            tmp.tenant,
	 	                                            tmp.modules,
	 	                                            tmp.managedOrgans,
	 	                                            tmp.addAutomaticActionPermissions,
	 	                                            tmp.annexaVersion,
	 	                                            tmp.activeMultipleRegisterOffice,
	 	                                            tmp.registerEntryOffices,
	 	                                            undefined,
	 	                                            undefined,
	 	                                            tmp.actionPermissions
	 	                                        );
	 	                                        
			 	                                $timeout(function() {
			 	                                   	$rootScope.LoggedUser.tokenAuthenticateWithPlugin = undefined;
			 	                                  	$state.go('annexa.dashboard'); });
	                       				}
	 	                                
	 	                                if(loggedUser.language && loggedUser.language.acronym){
	 	                                    $translate.preferredLanguage(loggedUser.language.acronym);
	 	                                    tmhDynamicLocale.set(loggedUser.language.acronym);
	 	                                    $rootScope.app.language = loggedUser.language.acronym;
	 	                                    Language.setCurrent(loggedUser.language.acronym);
	 	                                    $rootScope.app.langColumn = Language.getActiveColumn();
	 	                                    if($translate.use() != loggedUser.language.acronym){
	 	                                        $translate.use(loggedUser.language.acronym).then(function(){
	 	                                            $translate.refresh();
	 	                                            postLogin();
	 	                                        });
	 	                                    }else{
	 	                                    	postLogin();
	 	                                    }
	 	                                }else{
	 	                                	postLogin();
	 	                                }
        	                            callLogin = false;
        	                            return $q.resolve("OK");
        	                        }
                       			}
                        	}
                        }
                    	if(callLogin){
	                		LoginFactory.authenticate(model, function(authToken) {
	                			if(CacheFactory.get("globalDataCache")){
	                				try {
	                            		CacheFactory.get("globalDataCache").put('LoggedUserData',authToken);
	                				} catch (error) {
	                        			console.error(error);
	                        		}
	                            }
	                            var authTokenDecoded = JSOG.decode(authToken);
	
	                            if(authTokenDecoded.userObject){
	
	                                var loggedUser = this.cargarUsuario(authTokenDecoded);
	
	                                var postLogin = function(){
	                                	LoginService.PostLoginActions(
	                                            authTokenDecoded.userObject,
	                                            authTokenDecoded.roles,
	                                            authTokenDecoded.token,
	                                            undefined,
	                                            authTokenDecoded.tenant,
	                                            authTokenDecoded.modules,
	                                            authTokenDecoded.managedOrgans,
	                                            authTokenDecoded.addAutomaticActionPermissions,
	                                            authTokenDecoded.annexaVersion,
	                                            authTokenDecoded.activeMultipleRegisterOffice,
	                                            authTokenDecoded.registerEntryOffices,
	                                            undefined,
	                                            undefined,
	                                            authTokenDecoded.actionPermissions
	                                        );
	                                        
	                                        $timeout(function() {
	                                        	$rootScope.LoggedUser.tokenAuthenticateWithPlugin = undefined;
	                                        	$state.go('annexa.dashboard'); });
	                                }
	                                
	                                if(loggedUser.language && loggedUser.language.acronym){
	                                    $translate.preferredLanguage(loggedUser.language.acronym);
	                                    tmhDynamicLocale.set(loggedUser.language.acronym);
	                                    $rootScope.app.language = loggedUser.language.acronym;
	                                    Language.setCurrent(loggedUser.language.acronym);
	                                    $rootScope.app.langColumn = Language.getActiveColumn();
	                                    if($translate.use() != loggedUser.language.acronym){
	                                        $translate.use(loggedUser.language.acronym).then(function(){
	                                            $translate.refresh();
	                                            postLogin();
	                                        });
	                                    }else{
	                                    	postLogin();
	                                    }
	                                }else{
	                                	postLogin();
	                                }
	
	                                return $q.resolve("OK");
	                            } else {
	                            	$timeout(function() {
	                            		$rootScope.LoggedUser.tokenAuthenticateWithPlugin = undefined;
	                            		$state.go('access.signin'); });
	                                return $q.reject("No task");
	                            }
	                        }, function (error) {
	                        	$timeout(function() {
	                        		$rootScope.LoggedUser.tokenAuthenticateWithPlugin = undefined;
	                        		$state.go('access.signin'); });
	                            return $q.reject("No task");
	                        });
                    	}
                    	
                    }]
                }
            })
            .state('access.callbackOAuth', {
                url: '/callbackOAuth',
                data: {
                    title: 'global.toptitle',
                    displayName: 'global.breadcrumbs.home'
                },
                resolve: {
                    oAuthMethod:['LoginFactory','LoginService', '$timeout', '$q', '$state', '$rootScope', '$translate', 'tmhDynamicLocale', 'Language','CacheFactory', '$http', 'DialogsFactory', '$filter',
                    	function(LoginFactory, LoginService, $timeout, $q, $state, $rootScope, $translate, tmhDynamicLocale, Language, CacheFactory, $http, DialogsFactory, $filter) {
                    	var model= {username: $rootScope.LoggedUser.codeOAuth, password:$rootScope.LoggedUser.codeOAuth, authExternal: true, authenticationMethod: "OAUTH"};
                    	LoginFactory.authenticate(model, function(authToken) {
                    		if(authToken){
                        		var authTokenDecoded = JSOG.decode(authToken);
                        		if(authTokenDecoded.userObject){
                                	if(CacheFactory.get("globalDataCache")){
                                		try {
                                    		CacheFactory.get("globalDataCache").put('LoggedUserData',authToken);
                                		} catch (error) {
                                			console.error(error);
                                		}
                                    }
                                    var loggedUser = this.cargarUsuario(authTokenDecoded);
                                    $rootScope.desiredtoState = undefined;
                                    var postLogin = function(){
                                        LoginService.PostLoginActions(
                                            authTokenDecoded.userObject,
                                            authTokenDecoded.roles,
                                            authTokenDecoded.token,
                                            undefined,
                                            authTokenDecoded.tenant,
                                            authTokenDecoded.modules,
                                            authTokenDecoded.managedOrgans,
                                            authTokenDecoded.addAutomaticActionPermissions,
                                            authTokenDecoded.annexaVersion,
                                            authTokenDecoded.activeMultipleRegisterOffice,
                                            authTokenDecoded.registerEntryOffices,
                                            undefined,
                                            undefined,
                                            authTokenDecoded.actionPermissions
                                        );
                                        $timeout(function() {
                                        	$rootScope.LoggedUser.codeOAuth = undefined;
                                        	$state.go('annexa.dashboard'); });
                                    }
                                    
                                    if(loggedUser.language && loggedUser.language.acronym){
                                        $translate.preferredLanguage(loggedUser.language.acronym);
                                        tmhDynamicLocale.set(loggedUser.language.acronym);
                                        $rootScope.app.language = loggedUser.language.acronym;
                                        Language.setCurrent(loggedUser.language.acronym);
                                        $rootScope.app.langColumn = Language.getActiveColumn();
                                        if($translate.use() != loggedUser.language.acronym){
                                        	$translate.use(loggedUser.language.acronym).then(function(){
                                        		$translate.refresh();
                                        		postLogin();
                                        	});
                                        }else{
                                        	postLogin();
                                        }
                                    }else{
                                    	postLogin();
                                    }
                                    return $q.resolve("OK");
                                } else {
                                	$timeout(function() {
                                		DialogsFactory.error($filter('translate')('global.sigin.errorAzure'), $filter('translate')('DIALOGS_ERROR_MSG'));
                                		$rootScope.LoggedUser.codeOAuth = undefined;
                                		 $rootScope.desiredtoState = undefined;
                                		$state.go('access.signin'); });
                                	 return $q.reject("KO");
                                }
                        	}else{
                        		$timeout(function() {
                        			DialogsFactory.error($filter('translate')('global.sigin.errorAzure'), $filter('translate')('DIALOGS_ERROR_MSG'));
                            		$rootScope.LoggedUser.codeOAuth = undefined;
                            		 $rootScope.desiredtoState = undefined;
                            		$state.go('access.signin'); });
                        		return $q.reject("KO");
                        	}
                    	}, function (error) {
                    		DialogsFactory.error($filter('translate')('global.sigin.errorAzure'), $filter('translate')('DIALOGS_ERROR_MSG'));
                    		$rootScope.LoggedUser.codeOAuth = undefined;
                    		 $rootScope.desiredtoState = undefined;
                    		$state.go('access.signin'); 
                        	return $q.reject("KO");
                        });
                    }]
                }
            })
            .state('annexa.guided_procedure', {
                url: '/guidedProcedure',
                templateUrl: './views/layout/guidedProcedure.html',
                controller: 'GuidedProcedureController',
                data: {
                    title: 'global.commonAdmin.toptitle',
                    displayName: 'global.commonAdmin.tabs.guidedProcedure',
                    authenticate: true,
                    permissions: {
                        only: 'admin_tram_guided_procedures'
                    }
                }
            })
            .state('annexa.guided_procedure.new_guided_procedure',{
                url: '/new/:procedure',
                templateUrl: './views/layout/guidedProcedureBPM.html',
                controller: 'NewGuidedProcedureController',
                data: {
                    title: 'global.commonAdmin.toptitle',
                    displayName: 'global.literals.new',
                    authenticate: true,
                    permissions: {
                        only: 'admin_tram_guided_procedures'
                    }
                },
                resolve: {
                    guidedProcedure:['$state', '$stateParams', 'AdminFactory', function($state, $stateParams, AdminFactory) {
                        var procedure = -1;

                        if($stateParams.procedure) {
                            this.data.displayName = 'global.commonAdmin.titleEditProcedure';
                            procedure = $stateParams.procedure;
                        }

                        return AdminFactory.setupGuidedProcedures(procedure);
                    }]
                }
            })
            .state('annexa.dashboard', {
                url: '/dashboard',
                templateUrl: './views/layout/dashboard.html',
                data: {
                    title: 'global.toptitle',
                    displayName: 'global.breadcrumbs.home',
                    authenticate: true
                },
                resolve: {
                    loadDashboard:['annexaDashboardFactory', 'globalData', function(annexaDashboardFactory, globalData) {
                        return annexaDashboardFactory.loadDashboard();
                    }]
                }
            })
            .state('annexa.profile', {
                url: '/profile/:idUser',
                controller: 'ProfileController',
                templateUrl: './views/layout/profile.html',
                data: {
                    title: 'global.toptitleprofile',
                    displayName: 'global.toptitleprofile',
                    authenticate: true
                },
                resolve: {
                    loadDashboard:['GlobalDataFactory', function(GlobalDataFactory) {
                        return GlobalDataFactory.passwordMessages();
                    }]
                }
            })
            .state('annexa.alerts', {
                url: '/alerts',
                controller: 'AlertsController',
                templateUrl: './views/layout/alerts.html',
                data: {
                    title: 'global.toptitlealerts',
                    displayName: 'global.toptitlealerts',
                    authenticate: true
                }
            })
            .state('access', {
                url: '/access',
                template: '<div class="bg-auto w-full"><div ui-view class="fade-in-right-big smooth pos-rlt"></div></div>',
                data: {displayName: 'Access'},
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
					loadInjectCSS:['injectCSS', function(injectCSS) {
                       return injectCSS.setCSS();
                    }]
                }
            })
            .state('access.signin', {
                url: '/signin',
                controller: 'SignInController',
                templateUrl: './views/page/signin.html',
                data: {
                    displayName: 'Sign In'
                },
                resolve:{
                	authenticateExternal: ['$http', '$window', '$q', '$state', '$rootScope', '$timeout', 'LoginService', 'Language', 'tmhDynamicLocale', '$translate', 'LoginFactory', '$localStorage', 'CacheFactory', 
                	function ($http, $window, $q, $state, $rootScope, $timeout, LoginService, Language, tmhDynamicLocale, $translate, LoginFactory, $localStorage, CacheFactory) {
                		var deferrend = $q.defer();
                		$http({method: 'POST', url: './access/authenticateExternal'})
                        .success(function (data, status) {
                        	if(data){
                        		data = JSOG.decode(data);
                        		if(data && data.urlToUpgrade){
                        			if(data.urlToGet && data.applicationHeader && data.applicationId){
                        				var callToAuthenticateExternalFunction = function (){
                        					$localStorage.isRedirectedAuth = false;
                        					var header = {};
                        					header[data.applicationHeader] = data.applicationId;
	                        				$http({method: 'GET', url:data.urlToGet, headers: header, withCredentials:true}).success(function (dataGet, status) {
	                        					if(dataGet && dataGet.userDetails && dataGet.userDetails.user && dataGet.userDetails.user.name){
	                        						var callLogin = true;
	                        		            	if(CacheFactory.get("globalDataCache") &&  CacheFactory.get("globalDataCache").get('LoggedUserData')){
	                        		               		var cache = CacheFactory.get('globalDataCache').get('LoggedUserData');
	                        		               		if(cache && cache.userObject && cache.userObject.id){
	                        		               			var tmp = JSOG.decode(angular.fromJson(cache));
	                        		               			if(tmp && tmp.userObject && tmp.tenant == $localStorage.LoggedUserTenant && tmp.token == $localStorage.LoggedUserToken && tmp.userObject.id == $localStorage.LoggedUserID){
	    														$rootScope.externalAuthentication = true;
			                                                    var loggedUser = this.cargarUsuario(tmp);
			                                                    var postLogin = function(){
			                                                    	LoginService.PostLoginActions(
			                                                    			tmp.userObject,
			                                                    			tmp.roles,
			                                                    			tmp.token,
			    	                                                        undefined,
			    	                                                        tmp.tenant,
			    	                                                        tmp.modules,
			    	                                                        tmp.managedOrgans,
			    	                                                        tmp.addAutomaticActionPermissions,
			    	                                                        tmp.annexaVersion,
			    	                                                        tmp.activeMultipleRegisterOffice,
			    	                                                        tmp.registerEntryOffices,
			    	        	                                            undefined,
			    	        	                                            undefined,
			    	        	                                            tmp.actionPermissions
			    	                                                    );
			    	                                                    
			    	                                                    $timeout(function() {
			    	                                                    	$rootScope.LoggedUser.tokenAuthenticateWithPlugin = undefined;
			    	                                                    	$state.go('annexa.dashboard'); });
			                                                    }
			                                                    
			                                                    if(loggedUser.language && loggedUser.language.acronym){
			                                                        $translate.preferredLanguage(loggedUser.language.acronym);
			                                                        tmhDynamicLocale.set(loggedUser.language.acronym);
			                                                        $rootScope.app.language = loggedUser.language.acronym;
			                                                        Language.setCurrent(loggedUser.language.acronym);
			                                                        $rootScope.app.langColumn = Language.getActiveColumn();
			                                                        if($translate.use() != loggedUser.language.acronym){
			        	                                                $translate.use(loggedUser.language.acronym).then(function(){
			        	                                                    $translate.refresh();
			        	                                                    postLogin();
			        	                                                });
			                                                        }else{
			                                                        	postLogin();
			                                                        }
			                                                    }else{
			                                                    	postLogin();
			                                                    }
			                                                    callLogin = false;
			                                                    return $q.resolve("OK");
	                        		               			}
	                        		                	}
	                        		                }
	                        		            	if(callLogin){
	                        		            		var model= {username: dataGet.userDetails.user.name, password:btoa(angular.toJson(dataGet)), authExternal: true, authenticationMethod: dataGet.authenticationMethod};
			                                    		LoginFactory.authenticate(model, function(authToken) {
			                                                var authTokenDecoded = JSOG.decode(authToken);
															$rootScope.externalAuthentication = true;
			                                                if(authTokenDecoded.userObject){
			                                                	if(CacheFactory.get("globalDataCache")){
			                                                		try {
			                                                    		CacheFactory.get("globalDataCache").put('LoggedUserData',authToken);
			                                                		} catch (error) {
			                                                			console.error(error);
			                                                		}
			                                                    }
			                                                    var loggedUser = this.cargarUsuario(authTokenDecoded);
			                                                    
			                                                    var postLogin = function(){
				                                                    LoginService.PostLoginActions(
				                                                        authTokenDecoded.userObject,
				                                                        authTokenDecoded.roles,
				                                                        authTokenDecoded.token,
				                                                        undefined,
				                                                        authTokenDecoded.tenant,
				                                                        authTokenDecoded.modules,
				                                                        authTokenDecoded.managedOrgans,
				                                                        authTokenDecoded.addAutomaticActionPermissions,
				                                                        authTokenDecoded.annexaVersion,
				                                                        authTokenDecoded.activeMultipleRegisterOffice,
				                                                        authTokenDecoded.registerEntryOffices,
				        	                                            undefined,
				        	                                            undefined,
				        	                                            authTokenDecoded.actionPermissions
				                                                    );
				                                                    
				                                                    $timeout(function() {
				                                                    	$rootScope.LoggedUser.tokenAuthenticateWithPlugin = undefined;
				                                                    	$state.go('annexa.dashboard'); });
			                                                    }
			                                                    
			                                                    if(loggedUser.language && loggedUser.language.acronym){
			                                                        $translate.preferredLanguage(loggedUser.language.acronym);
			                                                        tmhDynamicLocale.set(loggedUser.language.acronym);
			                                                        $rootScope.app.language = loggedUser.language.acronym;
			                                                        Language.setCurrent(loggedUser.language.acronym);
			                                                        $rootScope.app.langColumn = Language.getActiveColumn();
			                                                        if($translate.use() != loggedUser.language.acronym){
			                                                        	$translate.use(loggedUser.language.acronym).then(function(){
			                                                        		$translate.refresh();
			                                                        		postLogin();
			                                                        	});
			                                                        }else{
			                                                        	postLogin();
			                                                        }
			                                                    }else{
			                                                    	postLongin();
			                                                    }
			                                                    return $q.resolve("OK");
			                                                } else {
			                                                	$localStorage.isRedirectedAuth = true;
			                                                	$window.location.href = data.urlToUpgrade;
			                                                	deferrend.reject("KO");
			                                                }
			                                            }, function (error) {
			                                            	$localStorage.isRedirectedAuth = true;
			                                            	$window.location.href = data.urlToUpgrade;
			                                            	deferrend.reject("KO");
			                                            });
	                        		            	}
	                        					}else{
	                        						$localStorage.isRedirectedAuth = true;
	                        						$window.location.href = data.urlToUpgrade;
	                        						deferrend.reject("KO");
	                        					}
	                    					}).error(function (msg, code) {
	                    						$localStorage.isRedirectedAuth = true;
	                    						$window.location.href = data.urlToUpgrade;
	                    						deferrend.reject("KO");
	                    					});
                        				}
                        				if($localStorage && $localStorage.isRedirectedAuth){
                        					callToAuthenticateExternalFunction();
                        				}else{
                        					$localStorage.isRedirectedAuth = true;
                        					if(data.urlToGetClient){
                        						$window.location.href = data.urlToGetClient;
                        					}else{
                        						callToAuthenticateExternalFunction();
                        					}
                        				}
                        			}else{
                        				$window.location.href = data.urlToUpgrade;
                        				deferrend.reject("KO");
                        			}
                        		}else{
                        			deferrend.resolve("OK");
                        		}
                        	}else{
                        		deferrend.resolve("OK");
                        	}
                        }).error(function (msg, code) {
                        	deferrend.reject(msg);
                        });
                		return deferrend.promise;
                    }]
                }
            })
            .state('annexa.bi', {
                url: '/bi',
                redirectTo: 'annexa.bi.user',
                views: {
                    "": {
                        templateUrl: './views/layout/bi.html'
                    }
                },
                data: {
                    title: 'global.literals.controlPanel',
                    displayName: 'global.literals.controlPanel',
                    authenticate: true,
                    permissions: {
                        only: ['bi_user', 'bi_global']
                    }
                },
                resolve: {
                    loadBIValues: ['AnnexaBIFactory', function (AnnexaBIFactory) {
                        return AnnexaBIFactory.loadBIValues();
                    }]
                }
            })
            .state('annexa.bi.user', {
                url: '/user',
                data: {
                    displayName: 'global.literals.user',
                    authenticate: true,
                    permissions: {
                        only: 'bi_user'
                    }
                },
                views: {
                    "mainbi@annexa.bi": {
                        controller: 'BIControllerUser',
                        templateUrl: './views/layout/biUser.html'
                    }
                }
            })
            .state('annexa.bi.global', {
                url: '/global',
                data: {
                    displayName: 'global.literals.global',
                    authenticate: true,
                    permissions: {
                        only: 'bi_global'
                    }
                },
                views: {
                    "mainbi@annexa.bi": {
                        controller: 'BIControllerGlobal',
                        templateUrl: './views/layout/biGlobal.html'
                    }
            }
            });
    }]);